<template>
  <div>
    <div class="wrapper">
      <div class="wrapper__card">
        <div class="wrapper__card__header">
          <div class="left">
            <span>Consultar consumo de créditos</span>
          </div>
        </div>

        <div class="wrapper__card__middle">
          <div>
            <v-text-field
              v-model="filter.startDate"
              label="Data de"
              outlined
              dense
              rounded
              type="date"
              hide-details
            ></v-text-field>
          </div>

          <div>
            <v-text-field
              v-model="filter.endDate"
              label="Data até"
              outlined
              dense
              rounded
              type="date"
              hide-details
            ></v-text-field>
          </div>

          <div>
            <z-btn rounded primary text="buscar" @click="getOptOutList()" />
          </div>
        </div>

        <!-- <v-row class="left">
          <div>
            <v-text-field
              v-model="filter.startDate"
              label="Data de"
              outlined
              dense
              rounded
              type="date"
              hide-details
            ></v-text-field>
          </div>
          <div class="ml-1">
            <v-text-field
              v-model="filter.endDate"
              label="Data até"
              outlined
              dense
              rounded
              type="date"
              hide-details
            ></v-text-field>
          </div>
          <div class="filterColumn ml-1">
            <z-btn rounded primary text="buscar" @click="getOptOutList()" />
          </div>
        </v-row> -->

        <div
          v-show="showInformations"
          class="wrapper__card__body"
          style="margin-bottom: 50px"
        >
          <v-row>
            <v-col lg="3" md="3" sm="12" xs="12" cols="12" class="mt-2">
              <CardReport
                icon="mdi-currency-usd"
                border-color-prop="#FFCE4D"
                main-color-prop="rgb(246, 189, 2)"
              >
                <template #title> Consumo Total </template>
                <template #value>
                  {{ formateNumberToLocal(totalCount) }}
                </template>
              </CardReport>
            </v-col>
            <v-col lg="3" md="3" sm="12" xs="12" cols="12" class="mt-2">
              <CardReport
                icon="mdi-email"
                border-color-prop="#411E6D"
                main-color-prop="rgb(100, 45, 108)"
              >
                <template #title> Consumo de E-mail </template>
                <template #value>
                  {{ formateNumberToLocal(emailCount) }}
                </template>
              </CardReport>
            </v-col>
            <v-col lg="3" md="3" sm="12" xs="12" cols="12" class="mt-2">
              <CardReport
                icon="mdi-message"
                border-color-prop="#30895E"
                main-color-prop="rgba(73, 203, 140, 1)"
              >
                <template #title> Consumo de Sms </template>
                <template #value>
                  {{ formateNumberToLocal(smsCount) }}
                </template>
              </CardReport>
            </v-col>
            <v-col lg="3" md="3" sm="12" xs="12" cols="12" class="mt-2">
              <CardReport
                icon="mdi-cellphone-message"
                border-color-prop="#53A9D8"
                main-color-prop="rgb(105, 188, 203)"
              >
                <template #title> Consumo de Push </template>
                <template #value>
                  {{ formateNumberToLocal(pushCount) }}
                </template>
              </CardReport>
            </v-col>
          </v-row>

          <v-row>
            <v-col lg="6" md="6" sm="12" xs="12" cols="12" class="mt-1">
              <v-card elevation="2" shaped class="mx-3 px-3 py-3">
                <div class="px-3 py-3 d-flex lign-center">
                  <h3>Envios Totais</h3>
                  <div />
                </div>

                <BarChart
                  :chart-data="totalData"
                  :chart-options="engageChartOptions"
                />
              </v-card>
            </v-col>
            <v-col lg="6" md="6" sm="12" xs="12" cols="12" class="mt-1">
              <v-card elevation="2" shaped class="mx-3 px-3 py-3">
                <div class="px-3 py-3 d-flex lign-center">
                  <h3>Envio de Email</h3>
                </div>

                <BarChart
                  :chart-data="emailData"
                  :chart-options="engageChartOptions"
                />
              </v-card>
            </v-col>
            <v-col lg="6" md="6" sm="12" xs="12" cols="12" class="mt-6">
              <v-card elevation="2" shaped class="mx-3 px-3 py-3">
                <div class="px-3 py-3 d-flex lign-center">
                  <h3>Envio de Sms</h3>
                </div>

                <BarChart
                  :chart-data="smsData"
                  :chart-options="engageChartOptions"
                />
              </v-card>
            </v-col>
            <v-col lg="6" md="6" sm="12" xs="12" cols="12" class="mt-6">
              <v-card elevation="2" shaped class="mx-3 px-3 py-3">
                <div class="px-3 py-3 d-flex lign-center">
                  <h3>Envio de Push</h3>
                </div>

                <BarChart
                  :chart-data="pushData"
                  :chart-options="engageChartOptions"
                />
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col lg="12" md="12" sm="12" xs="12" cols="12" class="mt-2">
              <v-card elevation="2" shaped class="mx-3 px-3 py-3">
                <div class="px-3 py-3 d-flex lign-center">
                  <h3>Envio Por Company</h3>
                </div>

                <z-table
                  class="table"
                  :headers="headers"
                  :items="paginatedItems"
                  icon-next="mdi-chevron-right"
                  :isLoading="loadingTableInfos"
                  icon-previous="mdi-chevron-left"
                  style="text-align: center !important"
                >
                  <template #no-data>
                    <div class="no-data-table">
                      <h3 style="text-align: center">Sem dados disponíveis.</h3>
                    </div>
                  </template>

                  <template v-slot:[`item.company`]="{ item }">
                    <span>
                      {{ getCompany(item._id) }}
                    </span>
                  </template>

                  <template v-slot:[`item.email`]="{ item }">
                    <span>
                      {{ formateNumberToLocal(item.count_email) }}
                    </span>
                  </template>

                  <template v-slot:[`item.sms`]="{ item }">
                    <span>
                      {{ formateNumberToLocal(item.count_sms) }}
                    </span>
                  </template>

                  <template v-slot:[`item.push`]="{ item }">
                    <span>
                      {{ formateNumberToLocal(item.count_push) }}
                    </span>
                  </template>

                  <template v-slot:[`item.count`]="{ item }">
                    <span>
                      {{ formateNumberToLocal(item.count) }}
                    </span>
                  </template>
                </z-table>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div v-show="!showInformations" class="wrapper no-data">
          <div class="wrapper__card">
            <z-loading />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ApiClient from "@/services/ApiClient";

import BarChart from "../Reports/components/Bar.vue";
import CardReport from "../../Shared/ZCardReportInfo.vue";
// import DoughnutChart from "../Reports/components/Doughnut.vue";

export default {
  components: {
    BarChart,
    CardReport,
    // DoughnutChart,
  },
  data() {
    return {
      filter: {
        page: 1,
        startDate: moment().add(-31, "days").format("yyyy-MM-DD"),
        endDate: moment().endOf("day").format("yyyy-MM-DD"),
      },
      totalPages: 0,
      confirmed: false,
      http: new ApiClient(),
      optOutObj: {},
      loadingTableInfos: false,
      showInformations: false,
      optOutAdd: {},
      loadingAdd: false,
      deleteId: null,
      types: ["phone", "email"],
      headers: [
        { text: "Company", value: "company", sortable: false, align: "center" },
        { text: "Email", value: "email", sortable: false, align: "center" },
        { text: "Sms", value: "sms", sortable: false, align: "center" },
        { text: "Push", value: "push", sortable: false, align: "center" },
        { text: "Consumo", value: "count", sortable: false, align: "center" },
      ],
      filtered: null,
      optOutAddEnabled: false,
      engageChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        // onClick: (e) => this.getChartClickedInfo(e.chart, e, "engage"),

        scales: {
          x: {
            stacked: false,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: false,
            ticks: {
              callback: function (value) {
                return value;
              },
            },
          },
        },

        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                let A = `${context.parsed.y}`;
                let B = A.replace(".", ",");
                let label =
                  `${context.dataset.label} ${
                    B ? parseFloat(B).toLocaleString("pt-BR") : 0
                  }` || "";
                return label;
              },
            },
          },
        },
      },
      paginatedItems: [],
      totalData: {
        labels: [],
        datasets: [{ data: [], label: "", backgroundColor: "#FFCE4D" }],
      },
      emailData: {
        labels: [],
        datasets: [{ data: [], label: "", backgroundColor: "#411E6D" }],
      },
      smsData: {
        labels: [],
        datasets: [{ data: [], label: "", backgroundColor: "#30895E" }],
      },
      pushData: {
        labels: [],
        datasets: [{ data: [], label: "", backgroundColor: "#53A9D8" }],
      },
      selectedCSVFile: null,
      totalCount: 0,
      emailCount: 0,
      smsCount: 0,
      pushCount: 0,
      companies: [],
      filterType: [
        { name: "Tudo", value: "" },
        { name: "Opt Out", value: "optout" },
        { name: "Bounce", value: "bounce" },
      ],
    };
  },
  created() {
    this.getOptOutList();
    this.getGroupCompaniesUsage();
  },
  watch: {},
  methods: {
    searchOptOut() {
      this.filter.page = 1;
      this.getOptOutList();
      this.getGroupCompaniesUsage();
    },
    getCompany(id) {
      const company = this.companies.find((x) => x.id === id);

      if (company) return company.company_name;
      else if (id === "Total") return "Total";
      else return "";
    },
    formateNumberToLocal(num) {
      return num ? parseFloat(num).toLocaleString("pt-BR") : 0;
    },
    formateDateTime(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    async getGroupCompaniesUsage() {
      this.loadingTableInfos = true;
      const req = await this.http.post(`usage/getGroupCompaniesUsage`, {
        startDate: this.filter.startDate + " 00:00:00",
        endDate: this.filter.endDate + " 23:59:59",
      });

      req.companyData.push({
        _id: "Total",
        count_email: req.companyData.reduce((a, b) => a + b.count_email, 0),
        count_sms: req.companyData.reduce((a, b) => a + b.count_sms, 0),
        count_push: req.companyData.reduce((a, b) => a + b.count_push, 0),
        count: req.companyData.reduce((a, b) => a + b.count, 0),
      });

      this.paginatedItems = req.companyData;
      this.companies = req.companies;
      this.loadingTableInfos = false;
    },
    async getOptOutList() {
      this.showInformations = false;
      try {
        let startDate = moment(this.filter.startDate);
        let endDate = moment(this.filter.endDate);

        console.log(startDate);
        console.log(endDate);

        let daydiff = endDate.diff(startDate, "days");

        console.log("daydif");
        console.log(daydiff);

        const req = await this.http.post(`usage/report`, {
          startDate: this.filter.startDate + " 00:00:00",
          endDate: this.filter.endDate + " 23:59:59",
        });

        let dates = [];
        for (let i = 0; i <= daydiff; i++) {
          let currentDate = moment(this.filter.startDate).add(i, "days");

          dates.push(currentDate);
        }

        this.totalData.labels = [];
        this.totalData.datasets[0].data = [];
        for (let i = 0; i < dates.length; i++) {
          this.totalData.labels.push(dates[i].format("DD/MM/yyyy"));

          let emailData = req.totalData.find(
            (x) => x._id === dates[i].format("yyyy-MM-DD")
          );

          if (emailData) this.totalData.datasets[0].data.push(emailData.count);
          else this.totalData.datasets[0].data.push(0);
        }

        // this.totalData.labels = req.totalData.map((x) => x._id);
        // this.totalData.datasets[0].data = req.totalData.map((x) => x.count);

        this.emailData.labels = [];
        this.emailData.datasets[0].data = [];

        for (let i = 0; i < dates.length; i++) {
          this.emailData.labels.push(dates[i].format("DD/MM/yyyy"));

          let emailData = req.totalData.find(
            (x) => x._id === dates[i].format("yyyy-MM-DD")
          );

          if (emailData)
            this.emailData.datasets[0].data.push(emailData.count_email);
          else this.emailData.datasets[0].data.push(0);
        }

        // this.emailData.labels = req.totalData
        //   .filter((x) => x.count_email > 0)
        //   .map((x) => x._id);

        // this.emailData.datasets[0].data = req.totalData
        //   .filter((x) => x.count_email > 0)
        //   .map((x) => x.count_email);

        this.smsData.labels = [];
        this.smsData.datasets[0].data = [];
        for (let i = 0; i < dates.length; i++) {
          this.smsData.labels.push(dates[i].format("DD/MM/yyyy"));

          let emailData = req.totalData.find(
            (x) => x._id === dates[i].format("yyyy-MM-DD")
          );

          if (emailData)
            this.smsData.datasets[0].data.push(emailData.count_sms);
          else this.smsData.datasets[0].data.push(0);
        }

        // this.smsData.labels = req.totalData
        //   .filter((x) => x.count_sms > 0)
        //   .map((x) => x._id);
        // this.smsData.datasets[0].data = req.totalData
        //   .filter((x) => x.count_sms > 0)
        //   .map((x) => x.count_sms);

        this.pushData.labels = [];
        this.pushData.datasets[0].data = [];
        for (let i = 0; i < dates.length; i++) {
          this.pushData.labels.push(dates[i].format("DD/MM/yyyy"));

          let emailData = req.totalData.find(
            (x) => x._id === dates[i].format("yyyy-MM-DD")
          );

          if (emailData)
            this.pushData.datasets[0].data.push(emailData.count_push);
          else this.pushData.datasets[0].data.push(0);
        }

        // this.pushData.labels = req.totalData
        //   .filter((x) => x.count_push > 0)
        //   .map((x) => x._id);
        // this.pushData.datasets[0].data = req.totalData
        //   .filter((x) => x.count_push > 0)
        //   .map((x) => x.count_push);

        this.totalCount = req.totalData.reduce(
          (partialSum, a) => partialSum + a.count,
          0
        );
        this.emailCount = req.totalData.reduce(
          (partialSum, a) => partialSum + a.count_email,
          0
        );
        this.smsCount = req.totalData.reduce(
          (partialSum, a) => partialSum + a.count_sms,
          0
        );
        this.pushCount = req.totalData.reduce(
          (partialSum, a) => partialSum + a.count_push,
          0
        );

        this.paginatedItems = req.companyData;

        this.showInformations = true;
      } catch (error) {
        this.$toast.error("Erro ao listar opt-outs");
        this.showInformations = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.filterColumn {
  margin-right: 10px;
}

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);
  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: auto;
    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-2;
      flex-wrap: wrap;
      .left {
        flex: 1 1 auto;
        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }
      }
      .right {
        flex: 2 1 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;
        & > div {
        }

        @media (max-width: 808px) {
          justify-content: flex-start;
          & > div {
            flex: 1 1 350px;
          }
        }
      }
    }

    &__middle {
      display: grid;
      gap: 0.5rem;
      max-width: 50%;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      margin-bottom: $z-s-1;
      padding: 0 0.3rem;
    }
    &__body {
      display: flex;
      flex-direction: column;
      gap: $z-s-1;
      height: auto;

      .table-wrapper {
        height: 500px !important;
        max-height: 500px;
        overflow-y: auto;
        @include trackScrollBar;

        .no-data-table {
          text-align: center;
        }

        @media (min-width: 1550px) {
          height: auto;
          max-height: 650px;
        }
      }

      @media (min-width: 720px) and (max-width: 1079px) {
        .table-wrapper {
          height: 400px;
          max-height: 400px;
        }
      }

      @media (max-width: 890px) {
        height: auto;
        .table-wrapper {
          height: 350px;
          max-height: 350px;
        }
      }

      // // NOVAS TABELAS ADICIONADAS
      @media (min-width: 750px) {
        ::v-deep .v-data-table {
          table {
            th {
              &:not(:first-child) {
                text-align: center !important;
              }
              &:last-child {
                // text-align: right !important;
              }
            }
            td {
              &:not(:first-child) {
                text-align: center !important;
                & > div,
                span {
                  margin: 0 auto !important;
                }
              }
              &:last-child {
                // text-align: right !important;
              }
            }
          }
        }
      }
    }
  }

  .badge {
    padding: 0.03rem 0.25rem;
    border-radius: 50px;
    font-size: 0.75rem;
    font-weight: 500;
    transition: 0.2s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    max-width: 58%;

    @media (max-width: 650px) {
      max-width: 100%;
    }
    &:hover {
      // background: rgb(236, 236, 236);
    }
  }
}

.list-options {
  background: #ffffff;
  &__item {
    padding: 0.5rem;
    cursor: pointer;
    transition: 0.2s ease;
    span {
      font-weight: 500;
    }
    &:hover {
      background: #e2e2e2;
    }
  }
}

.delete-confirmation {
  padding: 1rem;
  &__title {
    text-align: center;
    font-weight: 500;
  }
  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}

.no-data {
  .wrapper__card {
    height: 50vh !important;
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    justify-content: center;
    .loader {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    span {
      font-weight: 700;
      font-size: 1.1em;
      line-height: 15px;
    }
  }
}
</style>
